import zh_hk from '../languages/zh_hk.js'
import zh_cn from '../languages/zh_cn.js'
import en from '../languages/en.js'
import ja from '../languages/ja.js'

function translate(key) {
    if (window.sessionStorage.getItem('lang') == undefined) {
        return ""
    }

    let lang = window.sessionStorage.getItem('lang')

    switch(lang) {
        case 'zh_hk':
            return zh_hk[key]

        case 'zh_cn':
            return zh_cn[key]

        case 'en':
            return en[key]

        case 'ja':
            return ja[key]

        default :
            return ""
    }
}

export { translate }