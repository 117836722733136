const languages = {
    rsswithdrawal_amount               : 'Rss出金金额',
    amount_rsswithdrawal_amount        : 'Rss出金金额',
    fill_in_the_rsswithdrawal_amount   : '请填写Rss出金金额',
    rsswithdrawal_record               : 'Rss出金记录',
    minimum_usdt_rsswithdrawal_is      : 'USDT最低Rss出金50起，请重新修改Rss出金金额',
    bank_rsswithdrawal_amount          : 'Rss出金金额',
    banks_rsswithdrawal_amount         : '提取金额',
    rsswithdrawal_success_submitted    : 'Rss出金申请已成功提交',
    history_rsswithdrawal_amount       : 'Rss出金金额',
    history_rsswithdrawal_record       : 'Rss出金记录',
    history_rsswithdrawal_tips         : 'Rss出金小贴士',
    history_rsswithdrawal_information  : 'Rss出金信息',
    cancel_the_rsswithdrawal_request   : '您确定要取消Rss出金申请吗？',
    withdraw_rsswithdrawal_amount      : 'Rss出金金额',
    withdraws_rsswithdrawal_amount     : '提取金额',
    withdraw_rsswithdrawal_record      : 'Rss出金记录',
    withdraw_minimum_usdt_rsswithdrawal_is      : 'USDT最低Rss出金50起，请重新修改Rss出金金额',
    withdraw_rsswithdrawal_success_submitted    : 'Rss出金申请已成功提交',


    web_title                       : '瑞森GC',    
    choose_the_country              : '选择国家、地区',
    chinese_mainland                : '中国大陆',
    overseas_users                  : '海外用户',
    bank_card_information           : '银行卡信息',
    bank_card_upload                : '银行卡上传',
    bank_account_information        : '银行账户信息',
    bank_of_deposit                 : '开户银行',
    other_banks                     : '其他银行',
    please_enter                    : '请输入',
    sub_branch                      : '所属支行',
    please_fill_format_of_bank      : '请以「XX省XX市XX支行」格式填写',
    bank_card_number                : '银行卡号',
    submit_bank                     : '提交',
    please_select_the_account_bank  : '请选择开户银行',
    please_enter_your_branch        : '请输入所属支行',
    please_enter_bank_card_number   : '请输入银行卡号',
    other                           : '其他',
    capture_failed                  : '拍摄失败，请重试！',
    please_upload_bank_card         : '请上传银行卡',
    audit_failure                   : '审核失败',
    to_modify                       : '去修改',
    hkbank_card_information         : '银行卡信息',
    overseas_bank_account_info      : '海外银行账户信息',
    nation                          : '国家',
    hkbank_bank                     : '银行',
    hkbank_address                  : '银行地址',
    account_name                    : '账户名',
    hkbank_card_number              : '银行卡号',
    hkbank_account_address          : '账户地址',
    hkbank_submit                   : '提交',
    please_fill_in_the_country      : '请填写国家',
    please_fill_in_the_bank         : '请填写银行',
    please_fill_in_the_bank_address : '请填写银行地址',
    please_fill_in_the_account_name : '请填写账户名',
    please_fill_in_the_bank_number  : '请填写银行卡号',
    please_fill_account_address     : '请填写账户地址',
    please_fill_in_the_swift_code   : '请填写SWIFT CODE',
    identity_information            : '身份信息',
    id_photo                        : '证件照片',
    id_card_name                    : '姓名',
    id_card_identity_number         : '身份证号',
    id_card_gender                  : '性别',
    id_card_date_of_birth           : '出生日期',
    id_card_address                 : '地址',
    id_card_nationality             : '民族',
    id_card_issuing_authority       : '签发机关',
    id_card_validity_period         : '有效期',
    incorrect_identity_information  : '身份信息不正确？点此修改 ',
    other_information               : '其它信息',
    marital_status                  : '婚姻状况',
    please_choose                   : '请选择',
    id_card_email                   : '电子邮件',
    please_fill_in_the_email        : '请填写电子邮件',
    verification_code               : '验证码',
    please_fill_verification_code   : '请填写验证码',
    next_step                       : '下一步',
    unmarried                       : '未婚',
    please_enter_the_correct_email  : '请输入正确格式电子邮件',
    please_select_marital_status    : '请选择婚姻状况',
    please_fill_right_verification_code   : '请填写正确格式的验证码',
    married                         : '已婚',
    send_the_verification_code      : '发送验证码',
    verification_code_sent_success  : '验证码发送成功',
    id_card_to                      : ' 至 ',
    second                          : ' 秒 ',
    under_review                    : '审核中',
    pre_trial_is_generally_completed: '预审一般在1-2个工作日内完成，请耐心等待！',
    fill_in_bank_card               : '填写银行卡',
    domestic_bank_account           : '国内银行账户',
    overseas_bank_account           : '海外银行账户',
    please_select_a_bank_card_type  : '请选择银行卡类型',
    account_information             : '账户信息',
    main_network_type               : '主网类型',
    please_select_main_network_type : '请选择主网类型',
    usdt_address                    : '地址',
    please_enter_address            : '请输入地址',
    usdt_submit                     : '提交',
    userinfo_identity_information   : '身份信息',
    document_upload                 : '证件上传',
    personal_information            : '个人信息',
    userinfo_name                   : '姓名',
    please_fill_in_your_name        : '请填写姓名',
    userinfo_gender                 : '性别',
    userinfo_birthday               : '出生日期',
    select_date_of_birth            : '选择出生日期',
    userinfo_identity_number        : '身份证号',
    please_fill_in_the_id_number    : '请填写身份证号',
    userinfo_marital_status         : '婚姻状况',
    userinfo_id_card_email          : '电子邮件',
    userinfo_please_fill_the_email  : '请填写电子邮件',
    userinfo_verification_code      : '验证码',
    userinfo_fill_verification_code : '请填写验证码',
    userinfo_next_step              : '下一步',
    userinfo_man                    : '男',
    userinfo_woman                  : '女',
    userinfo_unmarried              : '未婚',
    userinfo_married                : '已婚',
    userinfo_please_fill_your_name  : '请填写姓名',
    please_fill_in_the_phone_number : '请填写电话号码',
    userinfo_please_enter_the_correct_email  : '请输入正确格式电子邮件',
    userinfo_please_fill_id_number  : '请填写身份证号',
    please_select_gender            : '请选择性别',
    please_select_date_of_birth     : '请选择出生日期',
    userinfo_please_marital_status  : '请选择婚姻状况',
    userinfo_send_verification_code : '发送验证码',
    please_enter_a_correct_email    : '请输入正确的电子邮件',
    userinfo_verification_code_sent_success  : '验证码发送成功',
    userinfo_capture_failed         : '拍摄失败，请重试！',
    please_upload_idcard_front_and_back:'请上传身份证正反面照片',
    
    options                         : '选择方式',
    deposit_amount                  : '入金金额',
    notification_check              : '通知查收',
    deposit_submission              : '入金提交',
    deposit_method                  : '入金方式',
    choose_a_deposit_method         : '选择入金方式',
    please_choose_a_deposit_method  : '请选择入金方式',
    deposit_amount_support_three    : '入金金额，支持小数点后三位',
    please_fill_the_deposit_amount  : '请填写入金金额',
    minimum_deposit                 : '最低入金',
    maximum_deposit                 : '最高入金',
    deposit_instructions            : '入金说明',
    submit_amount_options           : '提交',
    deposit_record                  : '入金记录',
    wrong_amount_minimum_deposit    : '金额错误，最低入金：',
    wrong_amount_maxmum_deposit     : '金额错误，最高入金：',
    sure_submit_deposit_request     : '您确定要提交入金申请吗？',
    please_wait                     : '请稍等...',
    apply_success                   : '入金申请已成功提交，请尽快上传转账回单进行确认。',
    to_upload                       : '去上传',
    all_loaded                      : '已加载全部',
    to_be_transferred               : '待转账',
    have_been_successful            : '已成功',
    has_been_cancelled              : '已取消',
    receipt_has_been                : '已上传回单',
    reviewed_and_approved           : '已审核',
    method_of_selection             : '选择方式',
    amount_of_deposit               : '入金金额',
    check_of_notice                 : '通知查收',
    deposit                         : '存入',
    gold_entry_record               : '入金记录',
    tips_for_bringing_in_money      : '入金小贴士',
    the_transfer_receipt_yet        : '您当前尚有入金申请未上传转账回单，是否前往上传？',
    gold_input_information          : '入金信息',
    info_amount_of_deposit          : '入金金额',
    method_of_deposit               : '入金方式',
    current_status                  : '当前状态',
    info_to_be_transferred          : '待转账',
    info_have_been_successful       : '已成功',
    info_has_been_cancelled         : '已取消',
    receipt_has_been_uploaded       : '已上传回单',
    info_reviewed_and_approved      : '已审核',
    reason_for_cancellation         : '取消原因',
    date_of_submission              : '提交日期',
    transfer_information            : '转账信息',
    receipt_uploading               : '回单上传',
    please_ensure_deposit_filled    : '* 请确保我司收到的实际到账金额与填写的入金金额一致。',
    confirm_upload_submission       : '确认上传提交',
    cancellation_of_deposit         : '取消入金',
    loading                         : '加载中...',
    sure_cancel_the_deposit         : '您确定要取消入金吗？',
    deposit_successfully_cancelled  : '入金已成功取消',
    please_upload_picture_receipt   : '请上传回单图片',
    sure_submit_the_receipt         : '您确定要提交入金回单吗？',
    info_please_wait                : '请稍等...',
    upload_successfully_wait_staff  : '上传成功，请等待工作人员审核',
    success_option                  : '选择方式',
    success_amount_of_deposit       : '入金金额',
    submitted_successfully_wait     : '提交成功，请等待系统处理',
    success_gold_entry_record       : '入金记录',

    android_app_download            : '瑞森GC证券理财版APP下载',

    no_more_records_yet             : '暂无更多记录',
    to_be_processed                 : '待处理',
    processed                       : '已处理',
    feedback                        : '意见反馈',
    title                           : '标题',
    please_enter_a_feedback_title   : '请输入反馈标题',
    detailed_description            : '详细描述',
    please_enter_a_description      : '请输入详细描述',
    feedback_submit                 : '提交',
    feedback_please_wait            : '请稍等...',
    feedback_successfully           : '意见反馈已成功提交',
    info_title                      : '标题',
    info_detailed_description       : '详细描述',
    info_state                      : '状态',
    info_to_be_processed            : '待处理',
    info_processed                  : '已处理',
    process_result                  : '处理结果',
    info_loading                    : '加载中...',

    overview                        : '概况',
    introduction                    : '简介',
    rate_structure                  : '费率结构',
    subscription_rate               : '认购费率',
    subscription_amount             : '认购金额 (M, 含认购费)',
    management_fee                  : '管理费',
    redemption_rate                 : '赎回费率',
    application_share_holding       : '申请份额持有时间 (N, 为自然日)',
    redemption_rates                : '赎回率',
    document                        : '文件',
    type                            : '类型',
    update_time                     : '更新时间',
    fund_introduction               : '基金介绍',
    helpful                         : '有帮助',
    no_help                         : '没有帮助',
    not_be_clicked_repeatedly       : '不可重复点击',
    increase_since_establishment    : '成立以来涨幅',
    net_unit_value_hk               : '单位净值(港元)',
    remaining_amount                : '剩余额度',
    historical_net_worth            : '历史净值',
    date                            : '日期',
    net_unit_value                  : '单位净值',
    daily_rise_and_fall             : '日涨跌幅',
    nets_no_more_records_yet        : '暂无更多记录',

    please_enter_the_search_keyword : '请输入搜索关键词',
    nets_info_loading               : '加载中...',

    app_download                    : '瑞森GC APP下载',

    verify_phone                    : '验证手机',
    please_enter_the_phone_number   : '请输入手机号码',
    please_fill_in_cell_phone_number: '请填写手机号码',
    please_enter_verification_code  : '请输入验证码',
    invite_next_step                : '下一步',
    invite_second                   : ' 秒 ',
    please_enter_valid_phone_number : '请输入正确的手机号',
    phone_registered                : '电话已注册',
    phone_registered_to_login       : '您的手机号已注册，您可以直接下载APP登录',
    go_download                     : '去下载',
    data_error_please_refresh       : '数据错误，请重新刷新页面',
    setting_login_password          : '设置登录密码',
    please_fill_in_the_password     : '请填写密码',
    please_enter_password           : '请输入密码',
    please_enter_password_again     : '请再次输入密码',
    please_fill_password_invite     : '请填写密码',
    confirm                         : '确认',
    passwords_entered_twice         : '两次输入的密码不一致，请检查',
    congratulations_registration    : '恭喜您，注册成功！',
    can_download_app                : '您现在可以点击下方按钮下载APP',
    invite_go_download              : '去下载',

    start_subscription              : '开始认购',
    deadline_for_subscription       : '截止认购',
    announced_the_lottery           : '公布中签',
    black_market_trading            : '暗盘交易',
    listing                         : '上市',
    basic_information               : '基本信息',
    cornerstone_investor            : '基石投资者',
    issue_information               : '发行资料',
    name                            : '名称',
    number_of_planned_subscriptions : '计划认购数(股)',
    proportion                      : '占比',
    actual_number_of_subscriptions  : '实际认购数(股)',
    sponsor                         : '保荐人',
    category                        : '类别',        
    underwriter                     : '承销商',   
    company_overview                : '公司概况',    
    company_profile                 : '公司简介',    
    company_executives              : '公司高管',
    position                        : '职务',

    login_has_expired               : '登录已失效，请重新登录',
    re_register                     : '重新登录',           
    
    notice_no_more_records_yet      : '暂无更多记录',
    notice_loading                  : '加载中...',

    previous_question               : '上一题',
    next_question                   : '下一题',
    confirm_submission              : '确认提交',
    submit_a_risk_assessment        : '您确定需要提交风险评测？',
    agree_to_the_relevant           : '我已阅读并同意相关',
    start_answering                 : '开始答题',
    risk_assessment_results         : '根据风险测评结果，您的风险类型为',
    your_risk_type                  : '您的风险类型',
    retest                          : '重新测试',
    my_invitation_code              : '我的邀请码：',
    click_to_copy                   : '点击复制',
    copy_link                       : '复制链接',
    save_picture                    : '保存图片',
    failed_to_get_image             : '获取图片失败',
    submitted_successfully          : '提交成功',
    contact_you_later               : '请保持您的联系方式畅通，我们稍后联系您！',
    submission_failed               : '提交失败',

    withdraw_option                 : '选择方式',
    withdrawal_amount               : '出金金额',
    accounts_receivable             : '收款账户',
    moderated                       : '等待审核',
    account_balance                 : '账户余额',
    amount_that_can_be_withdrawn    : '可提金额',
    amount_withdrawal_amount        : '出金金额',
    fill_in_the_withdrawal_amount   : '请填写出金金额',
    amount_next_step                : '下一步',
    withdrawal_record               : '出金记录',
    insufficient_account_balance    : '账户余额不足，当前余额：',
    minimum_usdt_withdrawal_is      : 'USDT最低出金50起，请重新修改出金金额',
    bank_option                     : '选择方式',
    bank_withdrawal_amount          : '出金金额',
    bank_accounts_receivable        : '收款账户',
    bank_moderated                  : '等待审核',
    banks_withdrawal_amount         : '提取金额',
    account_type                    : '账户类型',
    banks_account_information       : '账户信息',
    bank_nation                     : '国家',
    bank_please_enter               : '请输入',
    bank_bank                       : '银行',
    bank_code                       : '银行代码',
    bank_address                    : '银行地址',
    bank_account_name               : '账户名',
    bank_bank_card_number           : '银行卡号',
    bank_account_address            : '账户地址',
    bank_main_network_type          : '主网类型',
    please_select_the_main_network  : '请选择主网类型',
    banks_address                   : '地址',
    qrcode_picture                  : '二维码图片',
    bank_confirm_submission         : '确认提交',
    bank_please_fill_in_the_country : '请填写国家',
    bank_please_fill_in_the_bank    : '请填写银行',
    bank_please_fill_in_the_bank_code    : '请填写银行代码',
    bank_please_fill_in_the_bank_address : '请填写银行地址',
    bank_please_fill_in_the_account_name : '请填写账户名',
    bank_please_fill_in_the_bank_number  : '请填写银行卡号',
    bank_please_fill_account_address     : '请填写账户地址',
    bank_please_fill_in_the_swift_code   : '请填写SWIFT CODE',
    bank_please_select_the_main_network  : '请选择主网类型',
    bank_please_fill_in_the_address      : '请填写地址',
    please_upload_qrcode_picture         : '请上传二维码图片',
    recommend                       : 'ERC20（推荐）',
    trc20                           : 'TRC20',
    omni                            : 'OMNI',
    bank_domestic_bank_account      : '国内银行账户',
    bank_overseas_bank_account      : '境外银行卡',
    please_confirm_receiving_address: '请确认您的收款地址填写无误，我司将按照您所提供的收款地址转出资金',
    bank_name                       : '姓名',
    withdrawal_success_submitted    : '出金申请已成功提交',
    history_all_loaded              : '已加载全部',
    history_to_be_processed         : '待处理',
    history_audited                 : '已审核',
    history_cancelled               : '已取消',
    history_already_transferred     : '已划款',
    history_options                 : '选择方式',
    history_withdrawal_amount       : '出金金额',
    history_accounts_receivable     : '收款账户',
    history_moderated               : '等待审核',
    history_extract                 : '提取',
    history_withdrawal_record       : '出金记录',
    history_withdrawal_tips         : '出金小贴士',
    history_withdrawal_information  : '出金信息',
    history_handling_fee            : '手续费',
    history_submission_date         : '提交日期',
    history_audit_date              : '审核日期',
    history_transfer_date           : '转账日期',
    history_current_status          : '当前状态',
    history_process_result          : '处理结果',
    history_account_type            : '账户类型',
    history_cancel_apply            : '取消申请',
    history_loading                 : '加载中...',
    cancel_the_withdrawal_request   : '您确定要取消出金申请吗？',
    history_please_wait             : '请稍等...',
    successfully_canceled           : '出金申请已成功取消',
    withdraw_withdrawal_amount      : '出金金额',
    withdraw_accounts_receivable    : '收款账户',
    withdraw_moderated              : '等待审核',
    withdraw_account_balance        : '账户余额',
    withdraw_amount_that_can_be_withdrawn    : '可提金额',
    withdraws_withdrawal_amount     : '提取金额',
    withdraw_handling_fee           : '手续费',
    withdraw_total                  : '合计',
    fee_per_transaction             : '每笔手续费',
    withdraws_accounts_receivable   : '收款账户',
    withdraw_account_type           : '账户类型',
    please_select_account_type      : '请选择账户类型',
    withdraw_nation                 : '国家',
    withdraw_please_enter           : '请输入',
    withdraw_bank                   : '银行',
    withdraw_bank_address           : '银行地址',
    withdraw_account_name           : '账户名',
    withdraw_bank_card_number       : '银行卡号',
    withdraw_account_address        : '账户地址',
    withdraw_banks_address          : '地址',
    withdraw_qrcode_picture         : '二维码图片',
    withdraw_confirm_submission     : '确认提交',
    withdraw_withdrawal_record      : '出金记录',
    withdraw_please_fill_in_the_address      : '请填写地址',
    withdraw_please_upload_qrcode_picture    : '请上传二维码图片',

    withdraw_please_fill_in_the_country      : '请填写国家',
    withdraw_please_fill_in_the_bank         : '请填写银行',
    withdraw_please_fill_in_the_bank_address : '请填写银行地址',
    withdraw_please_fill_in_the_account_name : '请填写账户名',
    withdraw_please_fill_in_the_bank_number  : '请填写银行卡号',
    withdraw_please_fill_account_address     : '请填写账户地址',
    withdraw_please_fill_in_the_swift_code   : '请填写SWIFT CODE',
    withdraw_minimum_usdt_withdrawal_is      : 'USDT最低出金50起，请重新修改出金金额',
    withdraw_insufficient_account_balance    : '账户余额不足，当前余额：',
    withdraw_please_confirm_receiving_address:  '请确认您的收款地址填写无误，我司将按照您所提供的收款地址转出资金',
    withdraw_withdrawal_success_submitted    : '出金申请已成功提交',
}

export default languages