import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import './plugins/axiosBak.js'
import router from './plugins/router.js'

import { translate } from './utils/translate.js' // 引用语言文件
Vue.config.productionTip = false

// 设置语言 默认为简体中文
let reg = new RegExp("(^|[&?])lang=([^&]*)(&|$)", "i");
let r = window.location.hash.substr(1).match(reg); 
let lang = r && r[2] != undefined ? r[2] : 'zh_cn'
window.sessionStorage.setItem('lang', lang)

// 檢測是否存在token
let treg = new RegExp("(^|[&?])token=([^&]*)(&|$)", "i");
let tr = window.location.hash.substr(1).match(treg); 
let token = tr && tr[2] != undefined ? tr[2] : ''
window.sessionStorage.setItem('token', token)

document.title = translate('web_title')

Vue.prototype.translate = translate

Vue.use(Vant)

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')